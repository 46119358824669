





























































import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class HistorySecondYear extends Vue {
  expanded = false;

  toggleShowMoreText () {
    this.expanded = !this.expanded;
  }
}
