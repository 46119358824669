import { render, staticRenderFns } from "./History.vue?vue&type=template&id=6a3228a8&"
import script from "./History.vue?vue&type=script&lang=ts&"
export * from "./History.vue?vue&type=script&lang=ts&"
import style0 from "./History.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnimateHeading: require('/Users/markussh/git/theodora-tech-website/src/components/animate/AnimateHeading.vue').default,SectionHistoryFirstYear: require('/Users/markussh/git/theodora-tech-website/src/components/section/History/HistoryFirstYear.vue').default,SectionHistorySecondYear: require('/Users/markussh/git/theodora-tech-website/src/components/section/History/HistorySecondYear.vue').default,AnimateFade: require('/Users/markussh/git/theodora-tech-website/src/components/animate/AnimateFade.vue').default})
