

































import { Component, Vue } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component
export default class HistoryFirstYear extends Vue {
  mounted () {
    const image = this.$refs.image as HTMLElement;

    ScrollTrigger.matchMedia({
      '(min-width: 640px)' () {
        gsap.fromTo(image,
          { y: -16 },
          {
            scrollTrigger: {
              trigger: image,
              scrub: true,
            },
            y: -128,
            ease: 'none',
          });
      },
      '(max-width: 640px)' () {
        gsap.set(image, { clearProps: 'all' });
      },
    });
  }
}
