










import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class ResponsiveImage extends Vue {
  @Prop({ type: String, required: true })
    src!: string;

  @Prop({ type: String, required: true })
    alt!: string;

  @Prop({ type: String, required: true })
    width!: string;

  @Prop({ type: String, required: true })
    height!: string;

  @Prop({ type: String, required: true })
    sizes!: string;

  @Prop({ type: String, default: 'lazy' })
    loading!: string;
}
