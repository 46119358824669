










import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component
export default class AnimateFade extends Vue {
  @Prop(Boolean)
    appear!: boolean;

  @Prop({ type: String, default: 'div' })
    tag!: string;

  @Prop({ type: Number, default: 0.5 })
    duration!: number;

  @Prop({ type: Number, default: 0 })
    delay!: number;

  get appIsReady () {
    return this.$store.state.app.isReady;
  }

  @Watch('appIsReady')
  @Watch('appear')
  animateWhenLoaded (appIsReady) {
    if (appIsReady && this.appear)
      this.animate();
  }

  mounted () {
    if (this.appIsReady && this.appear)
      this.animate();
  }

  animate () {
    gsap.to(this.$refs.el as HTMLElement, {
      opacity: 1,
      duration: this.duration,
      delay: this.delay,
    });
  }
}
