















import { Component, Vue } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component
export default class SectionHistory extends Vue {
  animate = false;

  mounted () {
    const el = this.$refs.container as HTMLElement;
    const topTriggerPos = this.$store.state.navigation.scrollTriggerPos;
    ScrollTrigger.create({
      trigger: el,
      start: `top ${topTriggerPos}`,
      end: `bottom ${topTriggerPos}`,
      invalidateOnRefresh: true,
      onToggle: (e) => {
        if (e.isActive)
          this.$store.commit('navigation/setLightTheme', true);
      },
    });
    ScrollTrigger.create({
      trigger: el,
      start: 'top 80%',
      onEnter: (e) => {
        this.animate = true;
        e.kill();
      },
    });
  }
}
